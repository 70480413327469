import { type Tag } from '../types/helperTypes'
import { type Content } from '../types/basic'
import useTracking from '../composables/Tracking'

/**
 * This store manages the content data, including tags extracted from the content.
 * It provides functionalities to set new content, retrieve all tags, and filter tags
 * based on their internal or external status.
 *
 * Usage of this composable allows for centralized management of content-related
 * data and operations within a Vue application.
 */
export const useContentStore = defineStore('content', () => {
  const content = ref<Content | null>(null)
  const contentHeight = ref<number>(0)
  const pageType = ref<string | null>(null)
  const allTags = ref<Tag[]>([])
  const userState = ref<string>('Anonymous')
  const notFound = ref<object | null>(null)

  const setContent = (newContent: Content) => {
    content.value = newContent
    // Update allTags whenever new content is set
    allTags.value = newContent?.tags?.data || []
  }

  const setPageType = (newValue: string) => {
    pageType.value = newValue
  }
  const setUserState = (newValue: string) => {
    userState.value = newValue
  }

  const setNotFound = (notFoundContent: object | null): void => {
    notFound.value = notFoundContent
  }

  const setContentHeight = (height: number) => {
    contentHeight.value = height
  }

  const getNotFound = (): object | null => {
    return notFound
  }

  // Returns all tags
  const getAllTags = (): string[] => {
    return allTags.value.map(tag => tag.name)
  }

  // Returns tags that are not marked as internal
  const getExternalTags = (): Tag[] => {
    return allTags.value.filter(tag => !tag.internal)
  }

  // Returns tags that are marked as internal
  const getInternalTags = (): Tag[] => {
    return allTags.value.filter(tag => tag.internal)
  }

  const getChaptersSummary = () => {
    return content.value?.contents?.data.filter(
      widget => widget.type === 'chapters_summary'
    )
  }

  const { trackPageView } = useTracking()
  watch(content, doOnContentChanged)

  function doOnContentChanged() {
    trackPageView()
  }

  return {
    content,
    setContent,
    getAllTags,
    getExternalTags,
    getInternalTags,
    allTags,
    setPageType,
    pageType,
    userState,
    setUserState,
    setNotFound,
    getNotFound,
    contentHeight,
    setContentHeight,
    getChaptersSummary,
  }
})
