export default defineAppConfig({
  showTeaserDescription: {
    large: true,
    medium: false,
    small: false,
    tiny: false,
    default: false,
    ordered: false,
  },
  isPrimaryTextAlignCentered: false,
  withTeaserCategories: false,
  showBurgerMenu: true,
  showLogin: false,
  fallbackHeroImage: false,
  hasShortHeaderLogo: false,
  page: {
    layout: 'default',
    bannerTemplate: 'default',
  },
  category: {
    layout: 'default',
    bannerTemplate: 'default',
  },
  siteSpecific: {
    brands: {
      bom: {
        icon: {
          tag: 'bolig-magasinet',
          name: 'bom_icon',
        },
      },
      liv: {
        icon: {
          tag: 'magasinet-liv',
          name: 'liv_icon',
        },
      },
      cos: {
        icon: {
          tag: 'costume',
          name: 'cos_icon',
        },
      },
      test: {},
    },
  },
  languageSpecefic: {
    languages: {
      dk: {
        socialShare: [
          {
            type: 'link',
            link: 'https://www.facebook.com/womandk',
            icon: 'facebook',
            style: 'ml-[5px]',
          },
          {
            type: 'copy',
            icon: 'link',
            style: '',
          },
        ],
      },
    },
  },

  contentTypes: {
    video: {
      layout: 'default',
      darkmode: true,
    },
    article: {
      layout: 'default',
      bannerTemplate: 'default',
    },
    blog: {
      layout: 'blog',
    },
  },
})
