interface DeviceInfo {
  isDesktop: boolean
  isTablet: boolean
  isMobile: boolean
  [key: string]: any // For additional properties from useDevice
}

export const useDeviceType = (): DeviceInfo => {
  const { isDesktop, isTablet, isMobile, ...rest }: DeviceInfo = useDevice()

  // Simplified logic for determining currentDevice
  const currentDevice: string = isMobile
    ? 'mobile'
    : isTablet
      ? 'tablet'
      : 'desktop'

  return { currentDevice, isMobile, isTablet, isDesktop, ...rest }
}
