<script setup lang="ts">
  const { data } = defineProps<{ data: object }>()
  const { isMobile } = useDeviceType()

  const { position } = data
  const bannerSlot = computed(() => {
    if (['panorama', 'articlemodule'].includes(position) && isMobile) {
      return 'mob'
    } else if (position === 'rich_media_premium' && isMobile) {
      return 'mob_rich_media_premium'
    } else {
      return position
    }
  })
</script>

<template>
  <aside
    class="full-content bp-ads relative mx-auto grid w-full justify-center overflow-hidden"
  >
    <div :data-slot-name="bannerSlot" class="bam-ad-slot"></div>
  </aside>
</template>
