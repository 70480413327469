<script setup lang="ts">
  import PartialsTeaserItemTiny from '../Partials/TeaserItem/TeaserItemTiny.vue'
  import PartialsTeaserItemSmall from '../Partials/TeaserItem/TeaserItemSmall.vue'
  import PartialsTeaserItemLarge from '../Partials/TeaserItem/TeaserItemLarge.vue'
  import PartialsTeaserItemDefault from '../Partials/TeaserItem/TeaserItemDefault.vue'
  import PartialsTeaserItemsOrdered from '../Partials/TeaserItem/TeaserItemOrdered.vue'
  import PartialsTeaserItemMedium from '../Partials/TeaserItem/TeaserItemMedium.vue'

  type TeaserType =
    | 'large'
    | 'medium'
    | 'small'
    | 'tiny'
    | 'default'
    | 'ordered'

  const teaserData = defineProps<{
    teaserType: TeaserType
    data: TeaserItemData
  }>()

  const { teaserType } = teaserData

  // Object mapping each itemType to its corresponding component
  const componentMap: ComponentMap = {
    tiny: PartialsTeaserItemTiny,
    small: PartialsTeaserItemSmall,
    default: PartialsTeaserItemDefault,
    large: PartialsTeaserItemLarge,
    ordered: PartialsTeaserItemsOrdered,
    medium: PartialsTeaserItemMedium,

    /*         huge: PartialsTeaserItemHuge, */
  }

  // Compute the component based on the itemType prop
  const selectedComponent = computed(() => componentMap[teaserType])

  const appConfig = useAppConfig()

  const centerAlignmentText = appConfig?.isPrimaryTextAlignCentered
</script>

<template>
  <article class="group relative w-full overflow-hidden">
    <component
      :is="selectedComponent"
      :teaserData="teaserData?.data"
      :centerAlignmentText="centerAlignmentText || false"
    />
  </article>
</template>
