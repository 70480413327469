<script setup lang="ts">
  import NotSupported from '../../ui/notSupported.vue'
  import PartialsTeaserListTheme from '../../Partials/TeaserList/TeaserListTheme.vue'
  import PartialsTeaserListCategoryTheme from '../../Partials/TeaserList/TeaserListCategoryTheme.vue'
  import PartialsTeaserListDefault from '../../Partials/TeaserList/TeaserListDefault.vue'
  import PartialTeaserListOrdered from '../../Partials/TeaserList/TeaserListOrdered.vue'
  import PartialsTeaserListLatestNews from '../../Partials/TeaserList/TeaserListLatestNews.vue'

  interface ComponentMap {
    [key: string]: Component
  }

  const { data } = defineProps<{
    data: TeaserList
  }>()

  const type = ref(data.type)
  const teaserData = ref(data?.content?.data)
  const display_hint = ref(teaserData.value?.display_hint)

  // Watch for changes in the data.content.data.teasers array and update teaserData and display_hint

  // Object mapping each itemType to its corresponding component
  const componentMap: ComponentMap = {
    theme: PartialsTeaserListTheme,
    category_theme: PartialsTeaserListCategoryTheme,
    ordered_list: PartialTeaserListOrdered,
    latest_news: PartialsTeaserListLatestNews,
    slider: PartialsTeaserListLatestNews,
    default: PartialsTeaserListDefault,
    slider_video: PartialsTeaserListLatestNews,
  }

  // Compute the component based on the itemType prop
  const selectedDisplay = (componentName: keyof ComponentMap) => {
    const display = componentMap[componentName]

    if (!display) {
      return {
        display: NotSupported,
        props: { widgetName: type.value, display_hint: componentName },
      }
    }

    return { display }
  }
</script>

<template>
  <component
    :is="selectedDisplay(display_hint).display"
    v-bind="selectedDisplay(display_hint).props"
    :teaserData="data.content.data"
    data-component-type="Teaser list"
    :data-component-name="display_hint"
  />
</template>
