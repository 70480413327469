import {
  COMPOSITE_ACCESS_LEVELS,
  COMPOSITE_TEMPLATES,
  COMPOSITE_EDITORIAL_TYPES,
  COMPOSITE_SERVICES,
  MARKET_CODES,
} from '../types/basic.enums'
import type { DatalayerContent, DatalayerOrg } from '../types/tracking'
import type { Tag } from '../types/helperTypes'
// TODO don't forget about FORM events
export default function useTracking() {
  const { onElementScroll, scrollPosition } = useScrollHandler()
  const trackPageView = async (): Promise<void> => {
    const dataReady: DatalayerDataReady = {
      event: 'page view',
      environment: {
        isProduction: (process.env.SITE_ENV as string) === 'production',
        platform: {
          name: 'willow-frontend',
        },
        dataSource: 'web',
      },
      org: getOrg(),
      browser: {
        viewport: {
          width: window.innerWidth.toString(),
          height: window.innerHeight.toString(),
        },
      },
      // TODO make device to be calculated with useDevice
      device: {
        screen: {
          width: '1090',
          height: '1031',
        },
      },
      content: getContent(),
    }
    pushData(dataReady)
    resetData()
  }

  const trackClick = (data: any): void => {
    const { host } = useRequestURL()
    const dataReady: DatalayerDataReady = {
      event: 'link click',
      engagement: {
        type: 'link click',
        component: getComponent(data?.el.target),
        destination: {
          url: host + data?.url,
          title: data?.title,
        },
      },
    }
    pushData(dataReady)
  }

  const trackInteraction = (
    interactionType: string,
    componentPosition: string,
    componentName: string,
    componentType: string,
    componentIndex: string | null = null,
    componentRange: string | null = null
  ) => {
    const dataReady: DatalayerDataReady = {
      event: `interaction ${interactionType}`,
      engagement: {
        type: interactionType,
        component: {
          position: componentPosition,
          name: componentName,
          type: componentType,
          ...(componentIndex !== null && { index: String(componentIndex) }),
          ...(componentRange !== null && { range: String(componentRange) }),
        },
      },
    }
    pushData(dataReady)
  }

  const pushData = (data: DatalayerDataReady) => {
    if (process.client) {
      window.dataLayer?.push(data)
    }
  }

  const resetData = () => {
    const defaultData = {
      content: undefined,
      engagement: undefined,
    }
    if (process.client) {
      window.dataLayer?.push(defaultData)
    }
  }

  const trackScrollBehaviour = (element: HTMLElement) => {
    const scrollPercentage = ref<number>(0)
    const thresholds = [25, 50, 75, 100]
    const reachedThresholds = ref(new Set<number>())

    onElementScroll(element, (event: Event, pos: number): void => {})
  }

  return {
    trackPageView,
    trackClick,
    trackInteraction,
    trackScrollBehaviour,
  }
}

// Helper methods below ---------------
const getComponent = (el: any) => {
  if (!el) return 'unset'
  const position = el.closest('[data-position]').dataset.position || undefined
  const type =
    el.closest('[data-component-type]').dataset.componentType || undefined
  const name =
    el.closest('[data-component-name]').dataset.componentName || undefined
  return { type, name, position }
}

const getOrg = (): DatalayerOrg => {
  const marketCode = process.env.MARKET_CODE as string
  return {
    brandCode: process.env.BRAND_CODE as string,
    market: MARKET_CODES[marketCode as keyof typeof MARKET_CODES],
    businessUnit: 'Bonnier Publications',
    service: COMPOSITE_SERVICES['maincontent'], // TODO check other
  }
}

const getContent = (): DatalayerContent | undefined => {
  const route = useRoute()
  const { host } = useRequestURL()
  const { content } = storeToRefs(useContentStore())

  if (!content.value) return undefined
  return {
    accessLevel: COMPOSITE_ACCESS_LEVELS['free'], // TODO change after auth logic will be done !
    index: '0', // TODO create logic
    location: host + route.fullPath,
    section: ['Redskabsskur', 'Bygninger', 'Bygninger'], // TODO create sections logic
    id: {
      value: content.value.id,
      system: 'willow-whitelabel',
    },
    type: 'guide',
    isStandalone: true,
    articleType: 'guide',
    title: content.value.title,
    trigger: 'page load',
    commercialType: 'editorial', // TODO check other and also for non composites
    wordCount: content.value.word_count,
    publishedTime: content.value.published_at,
    lastModifiedTime: content.value.updated_at,
    author: content.value.author?.name || undefined,
    tags: getTags(content),
    tagsHidden: '', // TODO to find array of hidden tags and make logic to check
    editorialType: COMPOSITE_EDITORIAL_TYPES['byggevejledning'], // TODO check other
    template: COMPOSITE_TEMPLATES['guide'], // TODO check other
  }
}

const getTags = (content: any): Tag[] | [] => {
  const data = content.value.tags?.data
  if (!data) return []
  return data.map((tag: Tag) => tag.name)
}
