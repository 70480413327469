import type { DateTimeData, DurationData } from '../types/helperTypes'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration, { type Duration } from 'dayjs/plugin/duration'

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(duration)

const timeTypes = { s: 'seconds', m: 'minutes', h: 'hours', d: 'days' }

/**
 * Returns a timestamp that's based on the provided timezone.
 *
 * @param datetime
 */
export function getLocaleDateTime(datetime: DateTimeData): string {
  return dayjs(datetime.date)
    .tz(datetime.timezone, true)
    .format('HH:mm:ss DD.MM.YY')
}

/**
 * Returns correct datetime based on timezone provided.
 *
 * @param datetime
 */
export function getLocaleDate(datetime: DateTimeData): string {
  return dayjs(datetime.date).tz(datetime.timezone, true).format('DD.MM.YY')
}

/**
 * Creates valid datetime attribute for an HTML time tag.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/time
 * @param datetime
 */
export function dateTimeIso(datetime: DateTimeData): string {
  return dayjs(datetime.date).tz(datetime.timezone, true).toISOString()
}

export function getISODuration(duration: DurationData): string {
  return dayjs
    .duration(
      Object.fromEntries(
        Object.entries(duration).map(([k, v]) => [timeTypes[k] || null, v])
      )
    )
    .toISOString()
}

export function sumISODurations(...durations: string[]): string {
  return durations
    .reduce(
      (accumulator: Duration, duration) =>
        accumulator.add(dayjs.duration(duration)),
      dayjs.duration({})
    )
    ?.toISOString()
}
