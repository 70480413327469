const localeToCountryCodes = {
  da: 'dk',
  se: 'sv',
  no: 'no',
  nl: 'nl',
  fi: 'fi',
  en: 'gb',
}

export function localeToCountryCode(locale: string): string {
  return localeToCountryCodes[locale]
}

export function localeWithCountryCode(locale: string): string {
  return `${locale}_${localeToCountryCode(locale)?.toUpperCase()}`
}

export function countryCodeToLocale(countryCode: string): string | null {
  return (
    Object.keys(localeToCountryCodes).find(
      (key: string) => localeToCountryCodes[key] === countryCode
    ) || null
  )
}
