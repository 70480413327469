import type { Script } from '@unhead/schema'

export const bauScript = (): Script => ({
  hid: 'bau-script-id',
  src: 'https://assets.bonad.io/bundles/bau-noprebid.js',
})

export const rubiconScript = (): Script => ({
  hid: 'rubicon-script-id',
  src: 'https://micro.rubiconproject.com/prebid/dynamic/12954.js?site=BP_DK',
})

export const trackingScript = (domain: string, id: string): Script => ({
  hid: 'tracking-script-id',
  src: `https://tracking.${domain}/gtm.js?id=${id}`,
  type: 'text/javascript',
})

export const pianoScript = (
  id: string,
  sandbox: string,
  xcenseId: string
): Script => ({
  hid: 'piano',
  src: 'https://cdn.tinypass.com/api/tinypass.min.js',
  type: 'text/javascript',
  charset: 'utf-8',
  async: true,
  onload: () => {
    window.tp = window.tp || []
    window.tp.push(['setAid', id])
    window.tp.push(['setCxenseSiteId', xcenseId])
    if (sandbox === 'true') {
      window.tp.push(['setSandbox', 'true'])
      window.tp.push(['setEndpoint', 'https://sandbox.tinypass.com/api/v3'])
    }
  },
})
