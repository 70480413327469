/**
 * Returns a js URL instance, if possible.
 * The method will automatically add host origin to the url, if not present.
 *
 * @param url
 */
export function safeURL(url: string): URL | null {
  try {
    return new URL(isRelativeUrl(url) ? useRequestURL().origin + url : url)
  } catch (e) {
    return null
  }
}
0

export function isRelativeUrl(url: string) {
  return (
    url === null ||
    url === '' ||
    url?.startsWith('/') ||
    url?.startsWith('#') ||
    (!url?.startsWith('http://') && !url?.startsWith('https://'))
  )
}
