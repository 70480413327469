<script setup lang="ts">
  import { computed, defineProps } from 'vue'
  import LinkTo from '~/components/LinkTo.vue'

  const props = defineProps({
    direction: {
      type: String,
      default: 'right',
    },
    size: {
      type: String,
      default: 'default',
    },
    type: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: null,
    },
    hash: {
      type: String,
      default: null,
    },
    query: {
      type: Object,
      default: () => ({}),
    },
  })

  const variations = {
    small: '1.25rem',
    default: '2.25rem',
    large: '3rem',
  }

  const directions = {
    up: 270,
    down: 90,
    left: 180,
  }

  const rotation = computed(() => `${directions[props.direction] ?? 0}deg`)

  const height = computed(() => variations[props.size] || variations['default'])

  const typeClass = computed(() => {
    switch (props.type) {
      case 'primary':
        return 'bg-category-primary hover:bg-category-secondary hover:text-primary border-small text-category-secondary border-primary-tone text-white'
      case 'secondary':
        return 'bg-base hover:bg-primary hover:text-base border-small text-primary border-primary-tone text-black '
      case 'tertiary':
        return 'bg-primary-accent border-small border-primary-accent text-neutral hover:bg-neutral hover:text-primary-accent'
      default:
        return ''
    }
  })

  const isDisabled = computed(() => props.disabled)
</script>

<template>
  <component
    :is="props.link ? LinkTo : 'button'"
    :class="[
      'aspect-1/1 flex h-[100%] items-center justify-center rounded-[100px] transition-all disabled:pointer-events-none disabled:opacity-30',
      typeClass,
    ]"
    :style="{
      transform: 'rotate(' + rotation + ')',
      minHeight: height,
      maxHeight: height,
    }"
    :disabled="isDisabled"
    :link="props.link"
    :query="props.query"
  >
    <slot />
  </component>
</template>
