import type { DirectiveBinding } from 'vue'
import type { InteractionData } from '../types/interaction'

export default defineNuxtPlugin(nuxtApp => {
  const interactionRef = ref<string | null>(null)
  nuxtApp.vueApp.directive('interaction', {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
      interactionRef.value = binding.value
      el.addEventListener('interaction:event', (event: Event) => {
        const {
          detail: { interaction },
        } = event as CustomEvent<{
          interaction: string
          originalEvent: MouseEvent
        }>
        if (interaction) {
          nuxtApp.callHook('interaction:event', {
            ...binding.value,
            interaction,
          } as InteractionData)
        }
      })
    },
  })
  nuxtApp.vueApp.directive('interaction-emit', {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
      interactionRef.value = binding.value
      el.addEventListener('click', event => {
        const interactionEvent: CustomEvent<{
          interaction: string
          originalEvent: MouseEvent
        }> = new CustomEvent('interaction:event', {
          bubbles: true,
          detail: {
            originalEvent: event,
            interaction: interactionRef.value || 'click',
          },
        })
        el.dispatchEvent(interactionEvent)
      })
    },
    updated(el: HTMLElement, binding: DirectiveBinding) {
      if (interactionRef.value !== binding.value) {
        interactionRef.value = binding.value
      }
    },
  })
})
