<script setup lang="ts">
  import PartialsTeaserListTheme from './TeaserListTheme.vue'
  const { teaserData } = defineProps<{ teaserData: TeaserListWrapper }>()

  const categoryStyle = computed(() => {
    if (teaserData?.category) {
      return {
        '--category-primary-color': teaserData?.category?.text_color,
        '--category-secondary-color': teaserData?.category?.color,
      }
    }
    return {}
  })
</script>

<template>
  <section class="full-content themed bp-main-grid" :style="categoryStyle">
    <div
      class="bg-base full-content lg:main-content text-primary px-4 lg:p-6 lg:px-6"
    >
      <PartialsTeaserListTheme
        class="col-span-full m-[0]"
        :teaserData="teaserData"
      />
    </div>
  </section>
</template>
