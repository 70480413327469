// stores/translations.ts
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useTranslationStore = defineStore('translation', () => {
  const translations = ref<any | null>(null)

  const setTranslation = (newTranslation: any) => {
    translations.value = newTranslation
  }

  /**
   * Fetches a specific translation from the store.
   *
   * @param key Key to look for in translations.
   * @param def The default value to return, if none is found
   */
  const getTranslation: (key: string, def?: string) => string = (
    key,
    def = ''
  ) =>
    key
      .split('.')
      .reduce(
        (prev: any, curr: any) => prev && prev[curr],
        translations.value
      ) ||
    def ||
    key

  return { translations, setTranslation, getTranslation }
})
