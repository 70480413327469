const isNavOpen = ref(false)

export function useNavigation() {
  const toggleNavigation = () => {
    isNavOpen.value = !isNavOpen.value
  }

  const closeNavigation = () => {
    isNavOpen.value = false
  }

  const closeOnEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeNavigation()
    }
  }

  const closeOnClickOutside = (event: MouseEvent) => {
    const targetElement = event.target as Element
    if (!targetElement.closest('aside')) {
      closeNavigation()
    }
  }

  const addEscapeListener = () => {
    window.addEventListener('keydown', closeOnEscape)
  }

  const removeEscapeListener = () => {
    window.removeEventListener('keydown', closeOnEscape)
  }

  return {
    isNavOpen,
    closeOnEscape,
    closeOnClickOutside,
    addEscapeListener,
    removeEscapeListener,
    toggleNavigation,
    closeNavigation,
  }
}
