import type { Link } from '@unhead/schema'

export type SeoLinkTag = {
  name: string
  link: Link
}

/**
 * Holds all the link tags that should be shown on the page.
 * All values are added as children, to the <head> tag of the page.
 * Should only be manipulated via. helper functions.
 *
 * @type { SeoLinkTag[] }
 */
export const links: SeoLinkTag[] = <SeoLinkTag[]>[]
export const lockedLinks: SeoLinkTag[] = <SeoLinkTag[]>[]

/**
 * Simply returns the local links array.
 * Should be used instead of directly accessing the metaTags array.
 *
 * @return SeoLinkTag[]
 */
export function getLinks(): SeoLinkTag[] {
  return [...links, ...lockedLinks]
}

/**
 * Used to add an instance to the local links array.
 * The name parameter is used as a unique identifier. Only one instance pr. name is possible.
 * Return the new index of added link tag, or null if not added.
 *
 * If locked is provided, the value won't be deleted on reset.
 *
 * @param { string } name
 * @param { Link } link
 * @param { boolean } locked
 * @return { null | number }
 */
export function addLink(
  name: string,
  link: Link,
  locked: boolean = false
): null | number {
  return linkExists(name)
    ? null
    : (locked ? lockedLinks : links).push({
        name,
        link,
      })
}

/**
 * Checks if link tag with a given name, is already in array.
 *
 * @param { string } name
 * @return boolean
 */
export function linkExists(name: string): boolean {
  return !!getLinks().find((link: SeoLinkTag) => link.name === name)
}

/**
 * Returns all locally saved link tags, excluding the mate data.
 *
 * @return Link[]
 */
export function prepareLinks(): Link[] {
  return getLinks().map((link: SeoLinkTag) => link.link)
}

export function resetLinks(): void {
  links.length = 0
}
