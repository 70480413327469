<script setup lang="ts">
  import { computed, defineProps } from 'vue'

  // Define the prop type
  const props = defineProps<{
    type: 'Market' | 'Primary' | 'Secondary'
  }>()

  // Define computed styles based on the prop value
  const buttonClass = computed(() => {
    switch (props.type) {
      case 'Market':
        return 'bg-primary-marketing mkt-subscribe-trigger border-small border-secondary-marketing font-button text-secondary-marketing p-4'
      case 'Primary':
        return 'bg-primary-button text-secondary-button text-white p-4 border-small border-secondary-button'
      case 'Secondary':
        return 'bg-secondary-button border-small border-primary-button text-primary-button p-4'
      default:
        return ''
    }
  })

  // Define computed styles for the --icon-color CSS variable
  const buttonStyle = computed(() => {
    switch (props.type) {
      case 'Market':
        return { '--icon-color': 'var(--secondary-marketing)' } // Example color for Market
      case 'Primary':
        return { '--icon-color': 'var(--secondary-button)' } // Example color for Primary
      case 'Secondary':
        return { '--icon-color': 'var(--primary-button)' } // Example color for Secondary
      default:
        return {}
    }
  })
</script>

<template>
  <button
    :class="[
      'grid w-full grid-cols-[var(--spacing-5)_1fr_var(--spacing-5)]',
      buttonClass,
    ]"
    :style="buttonStyle"
  >
    <slot />
  </button>
</template>
