/**
 * Calculates the outer height of a given element.
 * The outer height includes the margins of the elements.
 *
 * @param el
 */
export function elementOuterHeight(el: HTMLElement) {
  return el
    ? ['height', 'margin-top', 'margin-bottom']
        .map(key =>
          parseInt(window.getComputedStyle(el).getPropertyValue(key), 10)
        )
        .reduce((prev, cur) => prev + cur) + 'px'
    : null
}
