<script setup lang="ts">
  const appConfig = useAppConfig()

  const centerAlignmentText = appConfig?.isPrimaryTextAlignCentered
</script>

<template>
  <h2
    class="font-deck-title text-primary-accent col-span-12"
    :class="{ 'text-center': centerAlignmentText }"
  >
    <slot />
  </h2>
</template>
