export function useContentType() {
  const contentStore = useContentStore()

  const { content } = contentStore
  const contentType = ref('Article')
  const contentTypeConfig = ref(null)

  const getVocabularies = (taxonomy: string) => {
    const vocabularies = content?.vocabularies?.data
    const vocabulary = vocabularies?.find(
      (vocabulary: { taxonomy: string }) => vocabulary.taxonomy === taxonomy
    )
    return vocabulary
  }

  const getContentType = () => {
    const contentTypeVocabulary = getVocabularies('content_type')
    contentType.value = contentTypeVocabulary?.terms?.[0]?.name || 'Article'
  }

  const getContentTypeConfig = (appConfig: any) => {
    contentTypeConfig.value =
      appConfig.contentTypes[contentType.value.toLowerCase()]
  }

  // Call the functions to populate the refs
  getContentType()
  getContentTypeConfig(useAppConfig())

  return {
    contentType: computed(() => contentType.value),
    contentTypeConfig: computed(() => contentTypeConfig.value),
    getVocabularies,
  }
}
