<script setup lang="ts">
  const model = defineModel()

  const { type = 'text', placeholder = '' } = defineProps<{
    type?: string
    placeholder?: string
  }>()
</script>

<template>
  <div class="border-primary-tone flex gap-3 border-[2px] p-5">
    <input v-model="model" :type="type" :placeholder="placeholder" />
  </div>
</template>
